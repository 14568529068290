import { AddToCartButton } from '../add-to-cart-button'
import { Rating } from '../rating'

export function ProductListItemSkeleton(): JSX.Element {
  return (
    <div>
      <div className="relative">
        <div className="h-[215px] w-full bg-skeleton">
          <AddToCartButton disabled />
        </div>
      </div>
      <div className="bg-skeleton w-2/3 mb-2" />
      <div className="h-[20px] w-full animate-pulse mb-1 bg-skeleton" />
      <div className="h-[20px] w-1/3 animate-pulse mb-2 bg-skeleton" />
      <Rating
        value={0}
        voteCount={0}
        className="ml-[-5px]"
        voteCountClassName="font-bold text-xs m-0"
      />

      <div className="h-[16px] w-[40px] animate-pulse bg-skeleton mt-1" />
    </div>
  )
}
