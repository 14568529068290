'use client'

import { ProductListItemSkeleton } from '@/components/product-list-item/product-list-item-skeleton'
import { ClientOnlyComponent } from '@/components/wrappers/client-only-component'

export default function CategoryPageSkeleton() {
  return (
    <ClientOnlyComponent>
      <div className="flex flex-col bg-white absolute top-0 left-0 right-0 bottom-0 w-full h-full">
        <div className="container max-w-screen-desktop mx-auto flex flex-col flex-1 px-5 pb-20 2xl:px-0">
          {/* Breadcrumbs */}
          <div className="w-full max-w-[200px] h-4 animate-pulse bg-skeleton mt-1" />

          {/* Desktop */}
          <div className="hidden md:flex gap-12 my-4">
            {/* Sidebar */}
            <div className="w-[280px] h-full shrink-0 flex justify-center items-center">
              <div className="w-full h-[490px] md:h-full  shrink-0  cursor-pointer object-contain animate-pulse bg-skeleton" />
            </div>

            <div className="w-full">
              {/* Title & description */}
              <div className="h-8 w-1/3 animate-pulse bg-skeleton mb-4" />
              <div className="h-4 w-full animate-pulse bg-skeleton mt-2" />
              <div className="h-4 w-2/3 animate-pulse bg-skeleton mt-2" />

              {/* Filters */}
              <div className="flex gap-2 justify-end">
                <div className="w-[160px] h-4 animate-pulse bg-skeleton mt-6" />
                <div className="w-[160px] h-4 animate-pulse bg-skeleton mt-6" />
              </div>

              {/* Products grid */}
              <div className="mt-6">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {[...Array(12)].map((_, index) => (
                    <ProductListItemSkeleton key={index} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Mobile */}
          <div className="flex md:hidden gap-12 my-4">
            <div className="w-full">
              {/* Title & description */}
              <div className="h-6 mt-6 w-1/3 animate-pulse bg-skeleton mb-4" />

              <div className="h-4 w-full animate-pulse bg-skeleton mt-2" />
              <div className="h-4 w-2/3 animate-pulse bg-skeleton mt-2" />

              {/* Filters */}
              <div className="flex flex-col gap-2 justify-center mt-6">
                <div className="w-full h-5 animate-pulse bg-skeleton" />
                <div className="w-full h-5 animate-pulse bg-skeleton" />
              </div>

              {/* Products grid */}
              <div className="mt-6">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {[...Array(12)].map((_, index) => (
                    <ProductListItemSkeleton key={index} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClientOnlyComponent>
  )
}
