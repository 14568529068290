'use client'

import { useTranslations } from 'next-intl'

import { cn } from '@/common/utils/lib'
import { USPAreaComponent } from '../usp-area/usp-area-component'
import {
  BagIcon,
  HeartIcon,
  PackageIcon,
  SandWatchIcon,
} from '@/components/icons/svg'
import { ProductListItemSkeleton } from '@/components/product-list-item/product-list-item-skeleton'
import { ClientOnlyComponent } from '@/components/wrappers/client-only-component'

export default function HomepageSkeleton() {
  const isMobile = false
  const t = useTranslations('USP')

  return (
    <ClientOnlyComponent>
      <div className="flex flex-col bg-white absolute top-0 left-0 right-0 bottom-0 w-full h-full">
        {/* Banner */}
        <div
          className="w-full animate-pulse mx-auto bg-skeleton data-[ismobile=true]:aspect-square data-[ismobile=false]:aspect-[384/119]"
          data-ismobile={isMobile}
        />

        {/* USP */}
        <div className="container max-w-screen-desktop mx-auto mt-6 mb-9 px-5 2xl:px-0">
          <USPAreaComponent
            items={[
              {
                icon: HeartIcon,
                title: t('box1.title'),
                subtitle: t('box1.subtitle'),
              },
              {
                icon: SandWatchIcon,
                title: t('box2.title'),
                subtitle: t('box2.subtitle'),
              },
              {
                icon: PackageIcon,
                title: t('box3.title'),
                subtitle: t('box3.subtitle'),
              },
              {
                icon: BagIcon,
                title: t('box4.title'),
                subtitle: t('box4.subtitle'),
              },
            ]}
          />
        </div>

        {/* Categories by goal*/}
        <div className="container max-w-screen-desktop mx-auto px-5 2xl:px-0 mb-5">
          <div className="flex flex-col gap-2 h-fit mb-4 ">
            <div className="h-[16px] w-2/3 animate-pulse bg-skeleton" />
            <div className="h-[16px] w-2/3 animate-pulse bg-skeleton usm:hidden" />
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 mb-10">
            {[...Array(8)].map((_, index) => (
              <div key={index} className="flex flex-col gap-4">
                <div className="sm:h-[75px] aspect-square animate-pulse sm:aspect-auto bg-skeleton" />
                <div className="h-[16px] w-full animate-pulse sm:hidden mb-4 bg-skeleton" />
              </div>
            ))}
          </div>
        </div>

        {/* Featured products */}
        <div className="container max-w-screen-desktop mx-auto px-5 2xl:px-0">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-x-5 gap-y-7">
            {[...Array(12)].map((_, index) => {
              const isDesktop = index >= 4

              return (
                <div
                  key={index}
                  data-desktop={isDesktop}
                  className="flex flex-col data-[desktop=true]:md:hidden data-[desktop=true]:lg:block"
                >
                  <ProductListItemSkeleton />
                </div>
              )
            })}
          </div>
        </div>

        {/* Blog */}
        <div className="container max-w-screen-desktop mx-auto mt-12 pb-20 px-5 2xl:px-0">
          {/* Title and arrows */}
          <div className="flex justify-between items-center mb-4">
            <div className="w-[80px] h-[24px] animate-pulse bg-skeleton" />
            <div className="flex gap-2">
              <div className="w-[40px] h-[24px] animate-pulse bg-skeleton" />
              <div className="w-[40px] h-[24px] animate-pulse bg-skeleton" />
            </div>
          </div>
          {/* Posts */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {[...Array(4)].map((_, index) => {
              const isMobileHidden = index >= 1
              const isTabletVisible = index === 1
              const isDesktopOnly = index >= 2

              return (
                <div
                  key={index}
                  className={cn('flex flex-col', {
                    hidden: isMobileHidden,
                    'sm:block': isTabletVisible,
                    'lg:block': isDesktopOnly,
                  })}
                >
                  <div className="h-[200px] w-full animate-pulse mb-3 bg-skeleton aspect-[400/209]" />
                  <div className="h-[24px] w-5/6 animate-pulse mb-2 bg-skeleton" />
                  <div className="h-[16px] w-full animate-pulse mb-1 bg-skeleton" />
                  <div className="h-[16px] w-full animate-pulse mb-1 bg-skeleton" />
                  <div className="h-[16px] w-2/3 animate-pulse bg-skeleton" />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </ClientOnlyComponent>
  )
}
