'use client'

import { useEffect, PropsWithChildren } from 'react'

import { useSkeletonContext } from '@/providers/skeleton/skeleton-context'
import HomepageSkeleton from '@/modules/homepage/components/skeleton-loader/HomepageSkeleton'
import ProductPageSkeleton from '@/modules/product/components/skeleton/ProductPageSkeleton'
import CategoryPageSkeleton from '@/modules/category/components/skeleton/CategoryPageSkeleton'

export type SkeletonType = 'homepage' | 'product' | 'category' | ''

type PageSkeletonProps = PropsWithChildren & {
  source?: SkeletonType
}

export const PageSkeleton = ({ children, source }: PageSkeletonProps) => {
  const { isVisible, target, hide } = useSkeletonContext()

  useEffect(() => {
    return () => {
      hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return source ? (
    <div className="relative data-[ui=true]:min-h-screen" data-ui={isVisible}>
      {children}
      {isVisible && target === 'homepage' && <HomepageSkeleton />}
      {isVisible && target === 'product' && <ProductPageSkeleton />}
      {isVisible && target === 'category' && <CategoryPageSkeleton />}
    </div>
  ) : (
    children
  )
}
